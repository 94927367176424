import React, { PropsWithChildren } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { Login } from "pages/Login";
import Risks from "pages/Risk/Risks";
import Standards from "pages/Standards";
import LandingPage from "pages/LandingPage";
import { Dashboard } from "pages/Dashboard";
import { EditRisk } from "pages/Risk/EditRisk";
import { EditProject } from "pages/EditProject";
import { CreateRisk } from "pages/Risk/CreateRisk";
import DirectiveSearch from "pages/DirectiveSearch";
import { ProjectDetails } from "pages/Details/ProjectDetails";
import TopLevelDirectiveSearch from "pages/TopDirectiveSearch";
import { NewAnnex } from "pages/Backoffice/Questionaire/NewAnnex";
import { EditAnnex } from "pages/Backoffice/Questionaire/EditAnnex";
import { NewQuestion } from "pages/Backoffice/Questionaire/NewQuestion";
import { EditQuestion } from "pages/Backoffice/Questionaire/EditQuestion";
import { QuestionaireBackOffice } from "pages/Backoffice/Questionaire/QuestionaireBackOffice";
import { DokumenationBackOffice } from "pages/Backoffice/Dokumentation/DokumentationBackoffice";

import { DesktopSidebar } from "components/Navigation/DesktopSidebar";
import {
  AdminRoute,
  ProtectedRoute,
} from "components/ProtectedRoute/ProtectedRoute";
import { UserSettings } from "pages/Settings/UserSettings";
import { UserEdit } from "pages/Settings/UserEdit";
import { Create } from "pages/Project/Create";
import { EditBoundaries } from "pages/EditBoundaries";
import { Pricing } from "pages/Pricing/Pricing";
import Manufacturers from "pages/Manufacturer/Manufacturer";
import { EditManufacturer } from "pages/Manufacturer/EditManufacturer";
import { EditDocumentation } from "pages/Backoffice/Dokumentation/EditDocumentation";
import { NewDocumentation } from "pages/Backoffice/Dokumentation/NewDocumentation";
import { Documents } from "pages/Documents/Documents";
import RisksBackoffice from "pages/Backoffice/Risks/Risks";
import { CreateRiskBackoffice } from "pages/Backoffice/Risks/CreateRisk";
import { EditRiskBackoffice } from "pages/Backoffice/Risk/EditRisk";
import CheckoutTest from "pages/Backoffice/TEST/Checkout";
import { CreateManual } from "pages/Manual/NewManual";
import { Registration } from "pages/Onboarding/Registration";
import Checkout from "pages/Checkout/Checkout";
import { useLanguageQuery } from "utils/hooks";

const Routes: React.FunctionComponent<PropsWithChildren> = (props) => {
  useLanguageQuery();

  return (
    <Switch>
      <Route path="/" exact component={LandingPage} />
      <Route path="/login" exact component={Login} />
      <Route path="/signup" exact component={Registration} />
      <Route path="/pricing" exact component={Pricing} />

      <div className="h-screen flex overflow-hidden bg-gray-100">
        <DesktopSidebar />
        {props.children}
        <ProtectedRoute exact path="/dashboard" component={Dashboard} />
        <ProtectedRoute
          exact
          shouldPrompt
          path="/projects/:projectId/questionaire"
          component={TopLevelDirectiveSearch}
        />
        <ProtectedRoute
          exact
          shouldPrompt
          path="/projects/:projectId/questionaire/:directive"
          component={DirectiveSearch}
        />
        <ProtectedRoute
          exact
          shouldPrompt
          path="/manual"
          component={CreateManual}
        />
        <ProtectedRoute
          exact
          shouldPrompt
          path="/projects/:projectId/risks/create"
          component={CreateRisk}
        />
        <ProtectedRoute
          exact
          shouldPrompt
          path="/projects/:projectId/risks/:riskId/edit"
          component={EditRisk}
        />
        <ProtectedRoute
          exact
          path="/projects/:projectId/risks"
          component={Risks}
        />
        <ProtectedRoute
          exact
          path="/projects/:projectId/documents"
          component={Documents}
        />
        <ProtectedRoute
          exact
          shouldPrompt
          path="/projects/:projectId/edit/standards"
          component={Standards}
        />
        <ProtectedRoute
          exact
          shouldPrompt
          path="/projects/:projectId/edit/boundaries"
          component={EditBoundaries}
        />
        <ProtectedRoute
          exact
          shouldPrompt
          path="/projects/:projectId/edit"
          component={EditProject}
        />
        <ProtectedRoute exact path="/new" component={Create} />
        <ProtectedRoute
          exact
          path="/projects/:projectId"
          component={ProjectDetails}
        />
        <ProtectedRoute exact path="/checkout" component={Checkout} />
        {/* ********** MANUFACTURER ******** */}
        <ProtectedRoute
          exact
          path="/manufacturers/:manufacturerId"
          component={EditManufacturer}
        />
        <ProtectedRoute exact path="/manufacturers" component={Manufacturers} />
        {/* ********** BACKOFFICE ******** */}
        <AdminRoute
          exact
          path="/_backoffice/_test/checkout"
          component={CheckoutTest}
        />

        <AdminRoute
          exact
          path="/_backoffice/_questionaire/:questionaireId/edit"
          component={EditQuestion}
        />
        <AdminRoute
          exact
          path="/_backoffice/_annex/:annexId/edit"
          component={EditAnnex}
        />
        <AdminRoute
          exact
          path="/_backoffice/_questionaire/new"
          component={NewQuestion}
        />
        <AdminRoute exact path="/_backoffice/_annex/new" component={NewAnnex} />
        <AdminRoute
          exact
          path="/_backoffice/_questionaire"
          component={QuestionaireBackOffice}
        />
        <AdminRoute
          exact
          path="/_backoffice/_risks/new"
          component={CreateRiskBackoffice}
        />
        <AdminRoute
          exact
          path="/_backoffice/_risks/:riskId/edit"
          component={EditRiskBackoffice}
        />
        <AdminRoute
          exact
          path="/_backoffice/_risks"
          component={RisksBackoffice}
        />
        <AdminRoute
          exact
          path="/_backoffice/_docs"
          component={DokumenationBackOffice}
        />
        <AdminRoute
          exact
          path="/_backoffice/_docs/:documentationId/edit"
          component={EditDocumentation}
        />
        <AdminRoute
          exact
          path="/_backoffice/_docs/new"
          component={NewDocumentation}
        />
        <ProtectedRoute exact path="/settings" component={UserSettings} />
        <ProtectedRoute exact path="/settings/edit" component={UserEdit} />

        {/* We only ever reach this route after a successful signin. That signin is handled by the Passwordless component and therefore does not have an actual router-route. Once the signin succeeded we're directed *into* the application but still on that sign-in route which renders an empty component. Therefore we redirect it to dashboard */}
        <Route
          path="/signin"
          exact
          component={() => <Redirect to="/dashboard" />}
        />
      </div>
    </Switch>
  );
};

export default Routes;
