import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon } from "@heroicons/react/solid";

import i18n from "i18n";
import { Languages } from "types";

const flags: Record<Languages, string> = {
  [Languages.german]: "🇩🇪",
  [Languages.english]: "🇬🇧",
  [Languages.portuguese]: "🇧🇷",
};

export const LanguageSelector = () => {
  const { t } = useTranslation();
  const [langCode] = i18n.language.split("-");
  const [showOptions, setShowOptions] = React.useState(false);
  const [language, setLanguage] = React.useState(langCode);

  useEffect(() => {
    setLanguage(langCode);
  }, [langCode]);

  return (
    <div className="relative inline-block text-left w-full">
      <div>
        <button
          type="button"
          className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm  bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-cyan-500 p-2"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
          onClick={() => setShowOptions(!showOptions)}
        >
          {flags[language as Languages]} {t(`languages.${language}`)}
          <ChevronDownIcon
            className={`h-5 w-5 transition-transform -mr-1 ml-2 ${
              showOptions ? "transform rotate-180": ""
            }`}
          />
        </button>
      </div>

      {showOptions && (
        <div className="z-50 origin-top-left absolute left-0 mt-2 w-full rounded-md shadow-md bg-white ring-1 ring-black ring-opacity-5 bottom-12">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <li
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
              role="menuitem"
              onClick={() => {
                i18n.changeLanguage(Languages.german);
                setLanguage(Languages.german);
                setShowOptions(false);
              }}
            >
              🇩🇪 {t(`languages.${Languages.german}`)}
            </li>
            <li
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
              role="menuitem"
              onClick={() => {
                i18n.changeLanguage(Languages.english);
                setLanguage(Languages.english);
                setShowOptions(false);
              }}
            >
              🇬🇧 {t(`languages.${Languages.english}`)}
            </li>
            <li
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
              role="menuitem"
              onClick={() => {
                i18n.changeLanguage(Languages.portuguese);
                setLanguage(Languages.portuguese);
                setShowOptions(false);
              }}
            >
              🇧🇷 {t(`languages.${Languages.portuguese}`)}
            </li>
          </div>
        </div>
      )}
    </div>
  );
};
